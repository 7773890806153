.main-menu {
  width: 90%;
}

.main-menu ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.main-menu__item {
  border-right: 1px solid var(--gray-border);
}

.main-menu__item:first-of-type {
  border-left: 1px solid var(--gray-border);
}

.main-menu__item--sign-out {
  margin-left: auto;
  border-left: 0;
  border-right: 0;
}

.main-menu__link {
  transition: all ease 0.3s;
  font-size: .8rem;
  font-weight: 400;
  text-decoration: none;
  color: var(--gray-base);
  padding: .5rem;
  white-space: nowrap;
  border-bottom: 1px solid transparent;
}

.main-menu__link:hover {
  background: #d3d3d3;
}

.main-menu__link--active {
  color: var(--primary-color);
  border-bottom: 1px solid var(--primary-color);
}

@media (min-width: 48rem) {
  .main-menu__link {
    font-size: 1rem;
    padding: .5rem 1rem;
  }
}
