@media (min-width: 48rem) {
  .header {
    padding-top: .5rem;
  }
}

.header__logo {
  width: 56px;
}

.header__skip-link {
  transition: transform 0.3s;
  background: var(--primary-color);
  color: #fff;
  text-decoration: none;
  left: 40%;
  padding: .5rem;
  position: absolute;
  transform: translateY(-150%);
}

.header__skip-link:focus {
  transform: translateY(0%);
}
