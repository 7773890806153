.footer {
  height: 5rem;
  padding: 0 2.5rem;
  text-align: center;
}

.footer__text {
  font-size: 0.75rem;
  font-weight: 200;
  color: #c9c9c9;
  margin: 0;
  margin-bottom: 0.5rem;
}

.footer__logo {
  height: 32px;
}
