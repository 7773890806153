.grievance-form__checkbox {
  width: 100%;
}

.grievance-form__error {
  color: var(--chart-red);
  font-size: 0.8rem;
  margin-top: 0.25rem;
}

@media (min-width: 48rem) {
  .grievance-form__checkbox {
    width: 30%;
  }
}
