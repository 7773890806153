:root, [data-amplify-theme] {
  --primary-color: #1a658f;
  --primary-hover-color: #538bab;
  --primary-disabled-color: #c5d8e3;
  --gray-base: #323232;
  --gray-border: #e8e8e8;
  --gray-light: #f9f9f9;
  --chart-red: #eb3300;
  --amplify-colors-font-primary: var(--gray-base);
  --amplify-colors-brand-primary-100: var(--primary-color);
  --amplify-colors-brand-primary-90: var(--primary-hover-color);
  --amplify-colors-brand-primary-80: var(--primary-color) ;
  --amplify-colors-brand-primary-60: #538bab;
  --amplify-colors-brand-primary-40: var( --amplify-colors-brand-primary-60);
  --amplify-colors-brand-primary-20: var( --amplify-colors-brand-primary-60);
  --amplify-colors-brand-primary-10: #c5d8e3;

  --amplify-components-text-color: var(--amplify-colors-font-primary);
  --amplify-components-field-label-color: var(--amplify-colors-font-primary);
  --amplify-components-fieldcontrol-color: var(--amplify-colors-font-primary);
  --amplify-font-weights-medium: 400;

  --amplify-components-authenticator-modal-background-color: white;
  --amplify-components-authenticator-router-border-width: 0;

  --amplify-components-button-link-hover-border-color: transparent;
  --amplify-components-button-link-hover-background-color: transparent;
  --amplify-components-button-link-hover-color: var(--primary-color);
  --amplify-components-button-link-focus-border-color: transparent;
  --amplify-components-button-link-focus-background-color: transparent;
  --amplify-components-button-link-focus-color: var(--primary-color);
  --amplify-components-button-link-focus-box-shadow: transparent;
  --amplify-components-button-link-active-background-color: transparent;
  --amplify-components-button-link-active-border-color: transparent;
  --amplify-components-button-link-active-background-color: transparent;
  --amplify-components-button-link-active-color: var(--primary-color);

  --amplify-components-button-primary-background-color: var(--primary-color);
  --amplify-internal-button-disabled-border-color: var(--primary-disabled-color);
  --amplify-components-button-border-color: var(--primary-color);
  --amplify-components-button-color: var(--primary-color);
  --amplify-components-button-hover-color: var(--primary-hover-color);
  --amplify-components-button-hover-border-color: var(--primary-hover-color);
  --amplify-components-button-hover-background-color: transparent;
  --amplify-font-weights-bold: 400;

  --amplify-components-menu-border-radius: 4px;
  --amplify-components-menu-border-color: #e3e3e3;
  --amplify-components-table-header-font-size: .8rem;
  --amplify-components-table-data-font-size: .8rem;
  --amplify-components-table-data-font-weight: 300;

  --toastify-icon-color-success: #00b228;
}

body {
  margin: 0;
  font-family: 'Fira Sans', sans-serif;
  font-size: 16px;
  font-weight: 300;
  color: var(--amplify-colors-font-primary);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  transition: all ease 0.3s;
  color: var(--primary-color)
}

a:hover {
  color: var(--primary-hover-color)
}

/*
  min height for sticky footer
  14 rem calculated from both header & footer height
*/
.main {
  min-height: calc(100vh - 14rem);
}

.container {
  max-width: 90%;
  margin: 0 auto;
}

.amplify-button--link {
  padding: 0;
}

/* LOGIN  */
[data-amplify-authenticator] [data-amplify-router] {
  border-radius: .5rem;
  box-shadow: 0 0 1.8rem 0 rgba(159, 159, 159, 0.2);
}

[data-amplify-authenticator] [data-amplify-form] {
  padding-top: 11.25rem;
  position: relative;
}

[data-amplify-authenticator] [data-amplify-form]:after,
[data-amplify-authenticator][data-variation=modal]:after {
  background: url('../public/askyourteam-logo.svg');
  background-repeat: no-repeat;
  left: 50%;
  transform: translateX(-50%);
}

[data-amplify-authenticator] [data-amplify-form]:after {
  content: '';
  background-size: 5rem;
  background-position: center;
  position: absolute;
  top: 1.5rem;
  left: 50%;
  width: 5.5rem;
  height: 6rem;
}

[data-amplify-authenticator][data-variation=modal]:after {
  content: 'Powered by ';
  background-size: 1.6rem;
  background-position: right;
  position: fixed;
  bottom: 1.5rem;
  left: 50%;
  width: 6.5rem;
  height: 3.125rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.875rem;
}
