
.svg-icon--loading--dots__dot {
  opacity: 0;
  animation: showHideDot 1s ease-in-out infinite;
}

.svg-icon--loading--dots__dot:nth-child(1) { animation-delay: 0s; }
.svg-icon--loading--dots__dot:nth-child(2) { animation-delay: 0.15s; }
.svg-icon--loading--dots__dot:nth-child(3) { animation-delay: 0.3s; }


@keyframes showHideDot {
  0% { opacity: 0; }
  50% { opacity: 1; }
  60% { opacity: 1; }
  100% { opacity: 0; }
}
