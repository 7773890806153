
.grievance-table.amplify-table {
  border-collapse: separate;
  border-spacing: 0 0.5rem;
}

.grievance-table a {
  text-decoration: none;
}

.grievance-table th {
  border: 0;
  color: var(--gray-base);
  font-size: .8rem;
  font-weight: 400;
  padding: .5rem;
  white-space: nowrap;
}

.grievance-table td {
  font-size: .8rem;
  padding: .5rem;
  font-weight: 300;
}

@media (min-width: 48rem) {
  .grievance-table th,
  .grievance-table td {
    font-size: 1rem;
  }
}


.grievance-table td:first-child,
.grievance-table td:last-child {
  border-radius: .25rem;
}

.grievance-table .grievance-table__cell--anonymity,
.grievance-table__cell--supplier_name a {
  font-weight: 400;
}

.grievance-table .grievance-table__cell--status {
  text-align: right;
}
