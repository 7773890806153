@media (min-width: 48rem) {
  .error-boundary {
    padding: 16rem;
  }
}

.error-boundary__title {
  font-size: 2rem;
  font-weight: 500;
  margin-bottom: 2.5rem;
}

.error-boundary__error-code {
  margin-bottom: 2.5rem;
}

.error-boundary__text {
  margin-bottom: 1.5rem;
}
