
.user-table .user-table__head {
  background: var(--gray-border);
}

.user-table tr:nth-child(even) {
  background: var(--gray-light)
}

.user-table .user-table__cell--remove {
  width: 3rem;
  text-align: right;
  border-left: 1px solid var(--gray-border);
  cursor: pointer;
  transition: all ease 0.3s;
}

.user-table .user-table__cell--remove:hover {
  color: var(--primary-color)
}
