.status-pill {
  transition: all ease 0.3s;
  display: inline-block;
  color: var(--gray-base);
  line-height: normal;
  font-size: 0.75rem;
  padding: 0.25rem 1rem;
  text-align: center;
  border: 0;
  border-radius: 0.75rem;
  margin: 0;
  white-space: nowrap;
}

.status-pill--button {
  cursor: pointer;
}

.status-pill:first-letter {
  text-transform: capitalize;
}

.status-pill--open.status-pill--active {
  background-color: var(--chart-red);
  color: #fff;
}

.status-pill--button.status-pill--open:hover {
  background-color: var(--chart-red);
  color: #fff;
}

.status-pill--in_progress.status-pill--active {
  background-color: #ebd700;
  color: #fff;
}

.status-pill--button.status-pill--in_progress:hover {
  background-color: #ebd700;
  color: #fff;
}

.status-pill--closed.status-pill--active {
  background-color: #11A900;
  color: #fff;
}

.status-pill--button.status-pill--closed:hover {
  background-color: #11A900;
  color: #fff;
}
